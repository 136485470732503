<template>
  <BaseModal
    :id="modalId"
    :title="$label('account.membership.resumeModalTitle')"
    :static-modal="staticModal"
  >
    <template v-if="pausedSubscription" #body>
      <p class="mb-24">
        {{ $label('account.membership.resumeModalSubtitle') }}
      </p>

      <span class="text-label">{{ $label('common.membership') }} </span>
      <p class="mt-8 mb-16" data-testid="subscription-name">
        {{ pausedSubscription.nameSubscription }}
      </p>
      <span class="text-label">{{ $label('common.monthlyPrice') }}</span>
      <p class="mt-8 mb-16" data-testid="subscription-price">
        {{ pausedSubscriptionPrice }}
      </p>
      <span class="text-label">{{
        $label('account.membership.resumeDate')
      }}</span>
      <p class="mt-8 mb-16">
        {{ resumeDate }}
      </p>

      <p v-if="isImmediatePayment" class="mb-0">
        {{
          $label('account.membership.resumeImmediatePayment', {
            price: pausedSubscriptionPrice,
          })
        }}
      </p>
    </template>

    <template v-if="pausedSubscription" #footer>
      <BaseButton
        data-testid="confirm-btn"
        variant="primary"
        :class="['confirm-btn', { loading: isLoading }]"
        @click="confirmResume"
      >
        {{ $label('account.membership.resumeBtn') }}
      </BaseButton>
      <BaseButton variant="secondary" class="cancel-btn" @click="hideModal">
        {{ $label('common.cancel') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import currencyFormatter from '~/utils/currencyFormatter'
import dateFormatter from '~/utils/dateFormatter'

export default {
  props: {
    pausedSubscription: {
      type: [Object, Boolean],
      default: false,
    },
    resumeSubscription: {
      type: Function,
      required: true,
    },
    loggedInUser: {
      type: [Object, Boolean],
      default: false,
    },
    modalId: {
      type: String,
      default: 'resumeSubscription',
    },
    hideModal: {
      type: Function,
      default: () => {},
    },
    staticModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    pausedAt() {
      return this.pausedSubscription.pausedAt
    },
    isImmediatePayment() {
      return !this.loggedInUser.currentMonthSubscription
    },
    pausedSubscriptionPrice() {
      return currencyFormatter(this.pausedSubscription.monthlyPrice)
    },
    resumeDate() {
      return dateFormatter(new Date())
    },
  },
  methods: {
    async confirmResume() {
      try {
        this.isLoading = true

        await this.resumeSubscription({
          userId: this.loggedInUser.id,
        })

        this.$toast.success(
          this.$label('account.membership.resumeSuccessAlert'),
        )
      } catch (error) {
        this.$toast.error(this.$label('messages.errorOccuredMessage'))
        this.$sentryCaptureException(error)
      } finally {
        this.isLoading = false
        this.hideModal()
      }
    },
  },
}
</script>
